*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  color: #555;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0; }

a:active, a:focus,
button:active,
button:focus,
input:active,
input:focus,
select:active,
select:focus,
textarea:active,
textarea:focus {
  box-shadow: 0 0 0 3px orange !important;
  outline: none !important; }

[data-whatintent='mouse'] a:active,
[data-whatintent='mouse'] a:focus,
[data-whatintent='touch'] a:active,
[data-whatintent='touch'] a:focus, [data-whatintent='mouse']
button:active,
[data-whatintent='mouse']
button:focus,
[data-whatintent='touch']
button:active,
[data-whatintent='touch']
button:focus, [data-whatintent='mouse']
input:active,
[data-whatintent='mouse']
input:focus,
[data-whatintent='touch']
input:active,
[data-whatintent='touch']
input:focus, [data-whatintent='mouse']
select:active,
[data-whatintent='mouse']
select:focus,
[data-whatintent='touch']
select:active,
[data-whatintent='touch']
select:focus, [data-whatintent='mouse']
textarea:active,
[data-whatintent='mouse']
textarea:focus,
[data-whatintent='touch']
textarea:active,
[data-whatintent='touch']
textarea:focus {
  box-shadow: none !important; }

a {
  color: #337ab7;
  text-decoration: none; }
  a:focus, a:hover {
    text-decoration: underline; }

h1 {
  border-bottom: 1px solid #eee;
  font-size: 36px;
  font-weight: 500;
  margin: 20px 0 10px;
  padding-bottom: 9px; }

p {
  margin: 16px 0; }
  @media (min-width: 800px) {
    p {
      margin: 24px 0; } }

.container {
  box-sizing: content-box;
  font-size: 14px;
  line-height: 1.4;
  margin: 0 auto;
  max-width: 1100px;
  padding: 0 16px; }
  @media (min-width: 800px) {
    .container {
      padding: 0 24px; } }

.lede {
  font-size: 20px;
  font-weight: 300;
  line-height: 32px; }
  @media (min-width: 800px) {
    .lede {
      font-size: 22px; } }

.well {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin: 24px 0;
  padding: 24px; }
  .well::after {
    clear: both;
    content: '';
    display: table; }

@media (min-width: 800px) {
  .well-row {
    display: flex;
    margin-left: -24px; } }

.well-column > :first-child {
  margin-top: 0 !important; }

.well-column > :last-child {
  margin-bottom: 0 !important; }

@media (max-width: 799px) {
  .well-column + .well-column {
    border-top: 1px solid #ccc;
    margin-top: 32px;
    padding-top: 24px; } }

@media (min-width: 800px) {
  .well-column {
    padding-left: 24px;
    width: 50%; } }

.well-column button {
  appearance: none;
  background-color: #337ab7;
  border: 1px solid #2e6da4;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 8px 16px;
  text-align: center;
  transition: all 0.2s ease; }
  .well-column button:focus, .well-column button:hover {
    background-color: #286090;
    border-color: #204d74; }

.well-column label {
  color: #333;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px; }

.well-column p {
  margin: 16px 0; }
  .well-column p.checkbox label {
    font-weight: 400; }

.well-column input:not([type='submit']):not([type='checkbox']):not([type='radio']) {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  height: 32px;
  margin: 0;
  padding: 0 8px;
  width: 100%;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: all 0.2s ease; }
  .well-column input:not([type='submit']):not([type='checkbox']):not([type='radio'])::-webkit-input-placeholder {
    color: #999; }
  .well-column input:not([type='submit']):not([type='checkbox']):not([type='radio'])::-moz-placeholder {
    color: #999; }
  .well-column input:not([type='submit']):not([type='checkbox']):not([type='radio']):-ms-input-placeholder {
    color: #999; }
  [data-whatintent='mouse'] .well-column input:not([type='submit']):not([type='checkbox']):not([type='radio']):focus {
    border-color: #31708f;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(49, 112, 143, 0.6); }
  [data-whatinput='touch'] .well-column input:not([type='submit']):not([type='checkbox']):not([type='radio']):focus {
    border-color: #8a6d3b; }

.well-column select {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  height: 32px;
  margin: 0;
  padding: 0 8px;
  width: 100%;
  background-image: url(../images/select-arrow.svg);
  background-position: calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  background-size: 10px 6px;
  padding-right: 30px; }
  [data-whatintent='mouse'] .well-column select:focus {
    border-color: #31708f;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(49, 112, 143, 0.6); }
  [data-whatinput='touch'] .well-column select:focus {
    border-color: #8a6d3b; }
  @media (min-width: 800px) {
    .well-column select {
      min-width: 50%;
      width: auto; } }
  .well-column select::-ms-expand {
    display: none; }

.well-column textarea {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  height: 32px;
  margin: 0;
  padding: 0 8px;
  width: 100%;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  height: 5em;
  padding-bottom: 8px;
  padding-top: 8px;
  transition: all 0.2s ease; }
  [data-whatinput='touch'] .well-column textarea:focus {
    border-color: #8a6d3b; }
  [data-whatintent='mouse'] .well-column textarea:focus {
    border-color: #31708f;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(49, 112, 143, 0.6); }

.list-group {
  list-style: none;
  padding: 0; }

.list-group-item:first-child a {
  border-radius: 4px 4px 0 0; }

.list-group-item:last-child a {
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 4px 4px; }

.list-group-item a {
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom: none;
  color: #555;
  display: block;
  padding: 16px 16px;
  text-decoration: none;
  transition: all 0.2s ease; }
  .list-group-item a:active, .list-group-item a:focus {
    box-shadow: 0 0 0 3px orange !important;
    outline: none !important; }
  [data-whatintent='mouse'] .list-group-item a:active,
  [data-whatintent='mouse'] .list-group-item a:focus,
  [data-whatintent='touch'] .list-group-item a:active,
  [data-whatintent='touch'] .list-group-item a:focus {
    box-shadow: none !important; }
  .list-group-item a:focus, .list-group-item a:hover {
    background-color: #f5f5f5; }
  .list-group-item a:active, .list-group-item a:focus {
    position: relative; }

footer {
  font-size: 14px;
  margin: 32px 0; }
  footer::after {
    clear: both;
    content: '';
    display: table; }
  footer p {
    margin: 0; }
  footer .text-love {
    color: #a94442; }
  footer .pull-left {
    float: left;
    margin: 0; }
  footer .pull-right {
    float: right;
    margin: 0; }

/*
 * what-input styles
 */
.input-indicator,
.input-intent {
  border-radius: 3px;
  display: inline-block;
  padding: 0 3px;
  transition: all 0.2s ease; }

[data-whatinput='mouse'] .input-indicator.-mouse,
[data-whatintent='mouse'] .input-intent.-mouse {
  background-color: rgba(51, 122, 183, 0.2);
  box-shadow: 0 0 0 1px rgba(51, 122, 183, 0.3);
  color: #337ab7; }

[data-whatinput='keyboard'] .input-indicator.-keyboard,
[data-whatintent='keyboard'] .input-intent.-keyboard {
  background-color: rgba(255, 165, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(255, 165, 0, 0.3);
  color: orange; }

[data-whatinput='touch'] .input-indicator.-touch,
[data-whatintent='touch'] .input-intent.-touch {
  background-color: rgba(138, 109, 59, 0.1);
  box-shadow: 0 0 0 1px rgba(138, 109, 59, 0.3);
  color: #8a6d3b; }

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus {
  outline: none; }

html:not([data-whatinput='keyboard']) div:focus {
  outline: none; }
