*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  color: #555;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

a,
button,
input,
select,
textarea {
  @include a11y-focus;
}

a {
  color: #337ab7;
  text-decoration: none;

  @include hover {
    text-decoration: underline;
  }
}

h1 {
  border-bottom: 1px solid #eee;
  font-size: 36px;
  font-weight: 500;
  margin: 20px 0 10px;
  padding-bottom: 9px;
}

p {
  margin: ($unit * 2) 0;

  @media (min-width: 800px) {
    margin: ($unit * 3) 0;
  }
}
